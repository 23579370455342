import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileCard = _resolveComponent("ProfileCard")!
  const _component_PasswordCard = _resolveComponent("PasswordCard")!
  const _component_EmailCard = _resolveComponent("EmailCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ProfileCard, {
      user: _ctx.me,
      onUpdatedUser: _ctx.updateMe
    }, null, 8, ["user", "onUpdatedUser"]),
    _createVNode(_component_PasswordCard, {
      class: "mt-4",
      user: _ctx.me
    }, null, 8, ["user"]),
    _createVNode(_component_EmailCard, {
      class: "mt-4 mb-12",
      user: _ctx.me,
      onUpdatedUser: _ctx.updateMe
    }, null, 8, ["user", "onUpdatedUser"])
  ]))
}