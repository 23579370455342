import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header py-12 px-16" }
const _hoisted_3 = { class: "card-body py-12 px-16" }
const _hoisted_4 = { class: "d-flex align-items-stretch" }
const _hoisted_5 = { class: "d-flex flex-column justify-content-between min-w-300px ms-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageField = _resolveComponent("ImageField")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('users.edit.profile.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateDetails && _ctx.updateDetails(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ImageField, {
            "error-key": "profilePicture",
            errors: _ctx.editUser.errors,
            label: _ctx.$t('users.edit.profile.picture'),
            "model-value": _ctx.editUser.profilePicture,
            size: "lg",
            "onUpdate:modelValue": _ctx.setProfilePicture
          }, null, 8, ["errors", "label", "model-value", "onUpdate:modelValue"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FormGroup, {
              modelValue: _ctx.editUser.firstName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editUser.firstName) = $event)),
              "error-key": "firstName",
              errors: _ctx.editUser.errors,
              label: _ctx.$t('users.edit.profile.firstName'),
              name: "firstName"
            }, null, 8, ["modelValue", "errors", "label"]),
            _createVNode(_component_FormGroup, {
              modelValue: _ctx.editUser.lastName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editUser.lastName) = $event)),
              "error-key": "lastName",
              errors: _ctx.editUser.errors,
              label: _ctx.$t('users.edit.profile.lastName'),
              name: "lastName"
            }, null, 8, ["modelValue", "errors", "label"])
          ])
        ]),
        _createVNode(_component_BtnResource, {
          class: "mt-24",
          disabled: _ctx.editUser.isOriginal,
          resource: _ctx.editUser,
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('users.actions.updateDetails')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "resource"])
      ], 32)
    ])
  ]))
}