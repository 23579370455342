
import {PropType, defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import FormGroup from '@/components/common/form/FormGroup.vue';
import User from '@/models/User';
import pick from 'lodash/pick';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
    },
    props: {
        user: {
            type: Object as PropType<User>,
            required: true,
        },
    },
    emits: [
        'updated-user',
    ],
    setup(props) {
        return {
            editUser: ref<User>(new User(props.user.attributes)),
        };
    },
    methods: {
        async updateEmail() {
            const updateOptions = {
                data: pick(
                    this.editUser.getSaveData(),
                    [
                        'email',
                    ],
                ),
            };

            await this.editUser.update(updateOptions);

            this.$emit('updated-user', updateOptions.data);
        },
    },
});
