
import {PropType, defineComponent} from 'vue';
import BtnInviteModal from '@/components/teams/BtnInviteModal.vue';
import MembersTable from '@/components/teams/teams/Members.vue';
import {Portal} from 'portal-vue';
import Role from '@/library/enumerations/Role';
import Team from '@/models/Team';

export default defineComponent({
    components: {
        BtnInviteModal,
        MembersTable,
        Portal,
    },
    props: {
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    computed: {
        userIsAdmin(): boolean {
            return this.team.currentTeamUser?.roles?.includes(Role.ADMIN) || false;
        },
        headers(): string[] {
            const headers = [
                'name',
                'email',
                'joined',
            ];

            if (this.userIsAdmin) {
                headers.push('options');
            }

            return headers;
        },
    },
});
