import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "password-container" }
const _hoisted_2 = { class: "ms-1 fs-4 d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormGroup, _mergeProps(_ctx.$attrs, {
      "error-key": _ctx.errorKey,
      name: _ctx.name,
      "no-append-style": "",
      type: _ctx.inputType
    }), {
      "group-append": _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_Fa, {
            class: "cursor-pointer text-black-50",
            icon: _ctx.eyeIcon,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
          }, null, 8, ["icon"])
        ])
      ]),
      _: 1
    }, 16, ["error-key", "name", "type"])
  ]))
}