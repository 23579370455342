
import {PropType, defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import PasswordField from '@/components/common/form/PasswordField.vue';
import User from '@/models/User';
import pick from 'lodash/pick';

export default defineComponent({
    components: {
        BtnResource,
        PasswordField,
    },
    props: {
        user: {
            type: Object as PropType<User>,
            required: true,
        },
    },
    setup(props) {
        return {
            editUser: ref<User>(new User(props.user.attributes)),
        };
    },
    computed: {
        passwordsHaveValue(): boolean {
            return !!this.editUser.currentPassword
                && !!this.editUser.password;
        },
    },
    created() {
        this.editUser.set('currentPassword', '');
        this.editUser.set('password', '');
    },
    methods: {
        updatePassword() {
            const updateData = pick(
                this.editUser.getSaveData(),
                [
                    'currentPassword',
                    'password',
                ],
            );

            this.editUser.update({data: updateData});
        },
    },
});
