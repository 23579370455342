
import {defineComponent, ref} from 'vue';
import FormGroup from '@/components/common/form/FormGroup.vue';

/**
 * Creates a password input field.
 * Makes use of the Form Group component and appends a visibility icon to it.
 * Field can toggle between password and text type to show or hide the input.
 */
export default defineComponent({
    components: {
      FormGroup,
    },
    inheritAttrs: false,
    props: {
        errorKey: {
            type: [String, Array],
            default: 'password',
        },
        name: {
            type: String,
            default: 'password',
        },
    },
    setup() {
        return {
            showPassword: ref<boolean>(false),
        };
    },
    computed: {
        inputType(): string {
            return this.showPassword
                ? 'text'
                : 'password';
        },
        eyeIcon(): string {
            return this.showPassword
                ? 'eye-closed'
                : 'eye';
        },
    },
});
