
import {Component, defineComponent, ref} from 'vue';
import NavTab, {NavTabItem} from '@/components/common/NavTab.vue';
import EditProfile from '@/components/users/EditProfile.vue';
import {Portal} from 'portal-vue';
import TeamMembers from '@/components/users/TeamMembers.vue';
import TeamProfileCard from '@/components/teams/teams/ProfileCard.vue';
import TeamType from '@/library/enumerations/TeamType';
import authStore from '@/store/auth';
import get from 'lodash/get';
import {useRoute} from 'vue-router';
import userTeams from '@/store/userTeams';

const tabKeys = [
    'profile',
    'team',
    'members',
];

interface EditTabComponent {
    key: typeof tabKeys[number];
    component: Component;
    requiresTeam?: boolean;
    requiresTeamType?: boolean;
}

export default defineComponent({
    components: {
        NavTab,
        Portal,
    },
    setup() {
        const route = useRoute();
        const defaultView = 'profile';

        let viewKey = get(
            route,
            'query.view',
            defaultView,
        );

        viewKey = tabKeys.includes(viewKey)
            ? viewKey
            : defaultView;

        return {
            defaultView,
            activeView: ref<EditTabComponent['key']>(viewKey),
            currentTeam: userTeams.currentTeam,
            me: authStore.me,
            tabComponents: [
                {
                    key: 'profile',
                    component: EditProfile,
                },
                {
                    key: 'team',
                    component: TeamProfileCard,
                    requiresTeam: true,
                    requiresTeamType: true,
                },
                {
                    key: 'members',
                    component: TeamMembers,
                    requiresTeam: true,
                },
                /*
                 * Removed for now since the terms and conditions don't have copy text.
                 * Once moved back, add 'terms' to list of EditTabComponent keys.
                 * {
                 *     key: 'terms',
                 *     component: TermsConditions,
                 * },
                 */
            ] as EditTabComponent[],
        };
    },
    computed: {
        activeComponent(): Component {
            const activeTab = this.visibleTabComponents.find((tabComponent: EditTabComponent) => tabComponent.key === this.activeView);

            return activeTab
                ? activeTab.component
                : this.visibleTabComponents[0].component;
        },
        tabs(): NavTabItem[] {
            return this.visibleTabComponents.map((tab: EditTabComponent) => {
                let navSuffix = '';

                if (tab.requiresTeamType) {
                    navSuffix = this.currentTeam?.type === TeamType.TEAM
                        ? 'Team'
                        : 'Organisation';
                }

                return {
                    label: this.$t(`users.edit.${tab.key}.nav${navSuffix}`),
                    value: tab.key,
                    active: tab.key === this.activeView,
                };
            });
        },
        visibleTabComponents(): EditTabComponent[] {
            return this.currentTeam
                ? this.tabComponents
                : this.tabComponents.filter((tabComponent: EditTabComponent) => !tabComponent.requiresTeam);
        },
    },
    watch: {
        '$route.query.view': function handler(newValue?: string) {
            const newView = newValue && tabKeys.includes(newValue)
                ? newValue
                : this.defaultView;

            if (this.activeView !== newView) {
                this.activeView = newView;
            }
        },
    },
    methods: {
        setActiveView(view: EditTabComponent['key']) {
            this.activeView = view;

            this.$router.replace({
                name: this.$route.name!,
                query: {
                    view,
                },
            });
        },
    },
});
