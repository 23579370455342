import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header py-12 px-16 border-bottom" }
const _hoisted_3 = { class: "card-body py-12 px-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('users.edit.password.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updatePassword && _ctx.updatePassword(...args)), ["prevent"]))
      }, [
        _createVNode(_component_PasswordField, {
          modelValue: _ctx.editUser.currentPassword,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editUser.currentPassword) = $event)),
          class: "d-inline-block min-w-300px",
          "error-key": "currentPassword",
          errors: _ctx.editUser.errors,
          label: _ctx.$t('users.edit.password.oldPassword')
        }, null, 8, ["modelValue", "errors", "label"]),
        _createVNode(_component_PasswordField, {
          modelValue: _ctx.editUser.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editUser.password) = $event)),
          class: "d-inline-block min-w-300px mt-6",
          "error-key": "password",
          errors: _ctx.editUser.errors,
          label: _ctx.$t('users.edit.password.newPassword'),
          name: "newPassword"
        }, null, 8, ["modelValue", "errors", "label"]),
        _createVNode(_component_BtnResource, {
          class: "mt-24",
          disabled: !_ctx.passwordsHaveValue,
          resource: _ctx.editUser,
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('users.actions.saveNewPassword')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "resource"])
      ], 32)
    ])
  ]))
}