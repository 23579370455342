import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "me-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnInviteModal = _resolveComponent("BtnInviteModal")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_MembersTable = _resolveComponent("MembersTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('users.edit.title')), 1),
          (_ctx.userIsAdmin)
            ? (_openBlock(), _createBlock(_component_BtnInviteModal, {
                key: 0,
                team: _ctx.team
              }, null, 8, ["team"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_MembersTable, _mergeProps(_ctx.$attrs, {
      headers: _ctx.headers,
      "hide-navbar-top-link": "",
      team: _ctx.team
    }), null, 16, ["headers", "team"])
  ]))
}