
import EmailCard from '@/components/users/EmailCard.vue';
import PasswordCard from '@/components/users/PasswordCard.vue';
import ProfileCard from '@/components/users/ProfileCard.vue';
import {UserData} from '@/models/User';
import authStore from '@/store/auth';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        EmailCard,
        PasswordCard,
        ProfileCard,
    },
    setup() {
        const {me} = authStore;

        return {
            me,
        };
    },
    methods: {
        updateMe(userData: Partial<UserData>) {
            if (!this.me) {
                return;
            }

            this.me.fill(userData);
        },
    },
});
