import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-center"
}
const _hoisted_2 = { class: "whitespace-pre-wrap mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Portal = _resolveComponent("Portal")!
  const _component_NavTab = _resolveComponent("NavTab")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('users.edit.title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Portal, { to: "navbar-top-append" }, {
      default: _withCtx(() => [
        _createVNode(_component_NavTab, {
          class: "w-100 my-6",
          tabs: _ctx.tabs,
          onTabClicked: _ctx.setActiveView
        }, null, 8, ["tabs", "onTabClicked"])
      ]),
      _: 1
    }),
    (_ctx.me)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeComponent), {
          key: 0,
          team: _ctx.currentTeam
        }, null, 8, ["team"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('users.edit.notLoggedIn.title')), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('users.edit.notLoggedIn.body')), 1),
          _createVNode(_component_RouterLink, {
            class: "btn btn-primary mt-16",
            to: {name: 'auth.login'}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('users.edit.actions.pleaseLogin')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]))
  ]))
}